<script>
  import { navigate } from "svelte-routing";
  import TextInput from "../../components/Form/TextInput.svelte";
  import { login } from "../../helpers/apiHelper.svelte";
  import { setCookie } from "../../helpers/utils.svelte";
  import FooterSmall from "../../components/Footers/FooterSmall.svelte";
    import { notification } from "../../notification-handlers/notification.svelte";
  const registerBg2 = "/assets/img/register_bg_2.png";

  export let isMobile = false;
  let isLoading = false;
  async function handleSubmit(e) {
    isLoading = true;
    const loginFormData = new FormData(e.target);

    const res = await login(
      loginFormData.get("username"),
      loginFormData.get("rawPassword"),
      isMobile
    );
    var result = res;

    if (!result?.success) {
      notification.modal({
        title: 'Hata !',
        message: result.message
      })
      isLoading = false;
    } else {
      const apiKey = result?.content?.api_key;
      setCookie("bluc", apiKey);
      window.location.href = window.location.origin;
    }
  }
</script>

<div>
  <main>
    <section class="relative w-full h-full min-h-screen" class:py-40={!isMobile} class:flex={isMobile} class:items-center={isMobile} class:justify-center={isMobile}>
      <div
        class="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
        style="background-image: url({registerBg2});"
      />
      <div class="container mx-auto px-4 h-full">
        <div class="flex content-center items-center justify-center h-full">
          <div class="w-full lg:w-4/12 px-4">
            <div
              class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
            >
              <div class="flex-auto px-4 lg:px-10 py-10 pt-10 rounded-t">
                <form id="login-form" on:submit|preventDefault={handleSubmit}>
                  <div class="mb-3 w-full flex justify-center items-center">
                    <img src="/assets/img/blueonline_logo.png" />
                  </div>
                  <div class="relative w-full mb-3">
                    <TextInput
                      fieldName="username"
                      labelText={isMobile ? "Telefon Numarası" : "Kullanıcı Adı/Email"}
                      required
                    />
                  </div>

                  <div class="relative w-full mb-3">
                    <TextInput
                      fieldName="rawPassword"
                      labelText="Şifre"
                      required
                    />
                  </div>
                  <div>
                    <label class="inline-flex items-center cursor-pointer">
                      <input
                        id="customCheckLogin"
                        type="checkbox"
                        class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                      />
                      <span
                        class="ml-2 text-sm font-semibold text-blueGray-600"
                      >
                        Beni Hatırla
                      </span>
                    </label>
                  </div>

                  <div class="text-center mt-6">
                    <button
                      class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150 disabled:opacity-50"
                      type="submit"
                      disabled={isLoading}
                      form="login-form"
                    >
                      {isLoading ? 'Giriş Yapılıyor...' : 'Giriş Yap'}
                    </button>
                    <button
                      class="bg-gray-100 text-black active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150 disabled:opacity-50"
                      type="submit"
                      on:click={() => {
                        if (isMobile) {
                          navigate('/signup');
                        } else {
                          navigate('/signup');
                        }
                      }}
                      disabled={isLoading}
                      form="login-form"
                    >
                      KAYDOL
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div class="flex flex-wrap mt-6 relative">
              <div class="w-1/2">
                <a
                  href="#pablo"
                  on:click={(e) => e.preventDefault()}
                  class="text-blueGray-200"
                >
                  <small>Şifremi Unuttum</small>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterSmall absolute="true" />
    </section>
  </main>
</div>
