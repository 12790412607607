import { writable } from "svelte/store";

const createNotificationStore = () => {
    const { subscribe, set, update } = writable({
        modals: [],
        toasts: []
    });
    
    return {
        subscribe,
        addModal: modal => update(s => {
            s.modals.push(modal);
            return s;
        }),
        addToast: toast => update(s => {
            s.toasts.push(toast);
            return s;
        }),
        reset: () => set({
            modals: [],
            toasts: []
        })
    }
};

export const notificationStore = createNotificationStore();