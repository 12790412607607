<script>
    import Error from "./toastTypes/Error.svelte";
    import Success from "./toastTypes/Success.svelte";
    import Warning from "./toastTypes/Warning.svelte";

    export let visible = false;
    export let message = null;
    export let type = 'success';
    export let onClose = null;

    const types = {
        success: Success,
        error: Error,
        warning: Warning
    }

</script>
{#if visible}
    <div style="min-width: 200px;" on:click={onClose} class="relative z-50 flex items-center p-4 mb-4 w-full max-w-xs text-gray-500 bg-white rounded-lg shadow" role="alert">
        <svelte:component this={types[type]}/>
        <div class="ml-3 text-sm font-normal">{message}</div>
    </div>
{/if}