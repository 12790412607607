<script context="module">
    import { fetchWithHeaders, querifiedURL, getBaseURL } from "../utils.svelte";

    export async function getDepartments(isPlain = false) {
        const apiResp = await fetchWithHeaders(querifiedURL(getBaseURL() + '/department-management/departments', { plain: isPlain ? 1 : 0}));
        return apiResp;
    }
    
    export async function createDepartment(departmentData) {
        return await fetchWithHeaders(getBaseURL() + '/department-management/departments', {
            method: 'POST',
            body: JSON.stringify(departmentData)
        });
    }
    
    export async function getDepartmentByID(departmentID) {
        const apiResp = await fetchWithHeaders(getBaseURL() + '/department-management/departments/' + departmentID);
        return apiResp;
    }
    
    export async function deleteDepartmentByID(departmentID) {
        return await fetchWithHeaders(getBaseURL() + '/department-management/departments/' + departmentID, {
            method: 'DELETE',
        });
    }
    
    export async function updateDepartmentByID(departmentID, departmentData) {
        return await fetchWithHeaders(getBaseURL() + '/department-management/departments/' + departmentID, {
            method: 'PUT',
            body: JSON.stringify(departmentData)
        });
    }


    export async function getParentDepartments(isPlain = false) {
        const apiResp = await fetchWithHeaders(querifiedURL(getBaseURL() + '/department-management/parent-departments', { plain: isPlain ? 1 : 0}));
        return apiResp;
    }
    
    export async function createParentDepartment(departmentData) {
        return await fetchWithHeaders(getBaseURL() + '/department-management/parent-departments', {
            method: 'POST',
            body: JSON.stringify(departmentData)
        });
    }
    
    export async function getParentDepartmentByID(departmentID) {
        const apiResp = await fetchWithHeaders(getBaseURL() + '/department-management/parent-departments/' + departmentID);
        return apiResp;
    }
    
    export async function deleteParentDepartmentByID(departmentID) {
        return await fetchWithHeaders(getBaseURL() + '/department-management/parent-departments/' + departmentID, {
            method: 'DELETE',
        });
    }
    
    export async function updateParentDepartmentByID(departmentID, departmentData) {
        return await fetchWithHeaders(getBaseURL() + '/department-management/parent-departments/' + departmentID, {
            method: 'PUT',
            body: JSON.stringify(departmentData)
        });
    }

    export async function getSubDepartments(isPlain = false) {
        const apiResp = await fetchWithHeaders(querifiedURL(getBaseURL() + '/department-management/sub-departments', { plain: isPlain ? 1 : 0}));
        return apiResp;
    }
    
    export async function createSubDepartment(departmentData) {
        return await fetchWithHeaders(getBaseURL() + '/department-management/sub-departments', {
            method: 'POST',
            body: JSON.stringify(departmentData)
        });
    }
    
    export async function getSubDepartmentByID(departmentID) {
        const apiResp = await fetchWithHeaders(getBaseURL() + '/department-management/sub-departments/' + departmentID);
        return apiResp;
    }
    
    export async function deleteSubDepartmentByID(departmentID) {
        return await fetchWithHeaders(getBaseURL() + '/department-management/sub-departments/' + departmentID, {
            method: 'DELETE',
        });
    }
    
    export async function updateSubDepartmentByID(departmentID, departmentData) {
        return await fetchWithHeaders(getBaseURL() + '/department-management/sub-departments/' + departmentID, {
            method: 'PUT',
            body: JSON.stringify(departmentData)
        });
    }
</script>