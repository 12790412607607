<script>
    export let text = "";
    export let buttonType = "save";
    export let color = "bg-green-500";
    export let hoverColor = "hover:bg-green-600";
    export let activeColor = "active:bg-green-700";
    export let submitsForm = "";
    export let onClick = false;
    export let disabled = false;
    export let witdhClass = "";

    if (buttonType === "cancel") {
        color = "bg-gray-500";
        hoverColor = "hover:bg-gray-600";
        activeColor = "active:bg-gray-700";
    } else if (buttonType === "delete") {
        color = "bg-red-500";
        hoverColor = "hover:bg-red-600";
        activeColor = "active:bg-red-700";
    } else if (buttonType === "edit") {
        color = "bg-yellow-400";
        hoverColor = "hover:bg-yellow-500";
        activeColor = "active:bg-yellow-600";
    } else if (buttonType === "info") {
        color = "bg-blue-400";
        hoverColor = "hover:bg-blue-500";
        activeColor = "active:bg-blue-600";
    }
    
    
    if (disabled) {
        color = "bg-gray-400";
        hoverColor = "";
        activeColor = "";
    }
    $: (
        disabled = disabled
    )
</script>

<button
    class="{witdhClass} {color} {hoverColor} {activeColor} text-white font-bold uppercase text-sm px-6 py-3 rounded shadow {!disabled ? "hover:shadow-lg" : ""} outline-none focus:outline-none ease-linear transition-all duration-150"
    type={submitsForm ? "submit" : false}
    form={submitsForm || false}
    disabled={disabled}
    on:click={onClick}
>
    {text}
</button>
