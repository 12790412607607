<script>
    import { get } from "svelte/store";
    import { mobileStore } from "../../../store/mobile.store";
    import FillTest from "../../../views/fill-test/FillTest.svelte";
    export let testID = null;
    export let urlID = null;
    const storeValues = get(mobileStore);
    const userData = storeValues.userData;
</script>
<div>
    <FillTest
        urlID={urlID}
        phoneNumber={userData.phone}
    />
</div>