<script>
    import { onMount } from "svelte";
    import { Router, Route, navigate } from "svelte-routing";
    import { getCookie } from "../helpers/utils.svelte";
    import { getLoggedInUser } from "../helpers/apiHelper.svelte";
    import { mobileStore } from '../store/mobile.store';
    import MobileDashboardMain from "./mobile-pages/MobileDashboardMain.svelte";
    import MobileForgotPassword from "./mobile-pages/auth/MobileForgotPassword.svelte";
    import MobileLogin from "./mobile-pages/auth/MobileLogin.svelte";
    import MobileSettings from "./mobile-pages/MobileSettings.svelte";
    import BottomNavigation from "./mobile-pages/BottomNavigation.svelte";
    import MobileSignUp from "./mobile-pages/auth/MobileSignUp.svelte";
    import MobileVerification from "./mobile-pages/auth/MobileVerification.svelte";
    import MobileSetFirstPassword from "./mobile-pages/auth/MobileSetFirstPassword.svelte";
    import MobileCategoryList from "./mobile-pages/mobile-training-management/MobileCategoryList.svelte";
    import MobileVideoList from "./mobile-pages/mobile-training-management/MobileVideoList.svelte";
    import ViewProfile from "./mobile-pages/profile/ViewProfile.svelte";
    import HeaderNavbar from "./mobile-pages/HeaderNavbar.svelte";
    import MobileVideoView from "./mobile-pages/mobile-training-management/MobileVideoView.svelte";
    import BalanceHistory from "./mobile-pages/dem/BalanceHistory.svelte";
    import NotificationProvider from "../providers/NotificationProvider.svelte";
    import Tests from "./mobile-pages/tests/Tests.svelte";
    import MobileFillTest from "./mobile-pages/tests/MobileFillTest.svelte";

    export let url = "";
    let userData = null;
    let isLoading = true;

    mobileStore.subscribe(store => {
        console.log(store);
        userData = store?.userData;
    });

    onMount(async () => {
        let shouldLogin = window?.location?.pathname.indexOf('/auth') < 0
                        && window?.location?.pathname.indexOf('/fill-test') < 0
                        && window?.location?.pathname.indexOf('/sinav') < 0;
        if (getCookie("bluc")) {
          const userResponse = await getLoggedInUser();
          if (userResponse.success && userResponse?.content?.user) {
              mobileStore.setUserData(userResponse.content.user);
              shouldLogin = false;
          }
        }
        isLoading = false;
        if (shouldLogin) {
          navigate('/login');
        } else {
          navigate('/trainings/categories');
        }
    });

    function generateRoutePath(path, param = null) {
        let pathParam = [path, param].filter((i1) => i1).join('/');
        pathParam = pathParam.replaceAll('//','/');
        return pathParam;
    }

    const routeListWithAuth = [
        {
          path:'/',
          param: '*dashboard',
          subRoutes: [
            { path: '', component: MobileDashboardMain },
            { path: 'settings', component: MobileSettings }
          ],
        },
        {
          path:'/profile',
          param: '*profileManagement',
          subRoutes: [
            { path: 'view', component: ViewProfile },
          ],
        },
        {
          path: 'trainings',
          param: '*trainings',
          subRoutes: [
            { path: 'categories', component: MobileCategoryList },
            { path: 'categories/:categoryID/videos', component: MobileVideoList },
            { path: 'categories/:categoryID/videos/:videoID', component: MobileVideoView },
          ]
        },
        {
          path: 'tests',
          param: '*tests',
          subRoutes: [
            { path: '', component: Tests },
            { path: 'view/:testID/:urlID', component: MobileFillTest }
          ]
        },
        {
          path:'/dem',
          param: '*demManagement',
          subRoutes: [
            { path: 'balance-history', component: BalanceHistory },
          ],
        },
    ]

    const routeListWithoutAuth = [
        { path: '/login', component: MobileLogin },
        {
          path: '/signup',
          param: '*mobileSignUp',
          subRoutes: [
            { path: '', component: MobileSignUp },
            { path: 'verification', component: MobileVerification },
            { path: 'set-first-password', component: MobileSetFirstPassword }
          ]
        },
        { path: '/forgot-password', component: MobileForgotPassword }
    ]
</script>
<NotificationProvider />
<Router url="{url}">
    {#if isLoading}
      <span>Loading</span>
    {:else}
      {#if userData && userData.fullname }
        <HeaderNavbar/>
        <div style="height:calc(100vh - 5rem); max-height:calc(100vh - 5rem); overflow-y:auto;overflow-x:hidden">
          {#each routeListWithAuth as authRoute}
            {#if authRoute?.subRoutes?.length > 0}
              <Route path={generateRoutePath(authRoute.path, authRoute?.param)}>
                <div class="relative bg-blueGray-100 min-h-full flex flex-col justify-between">
                  <div class="px-4 md:px-10 mx-auto w-full mt-0 flex-grow">
                    <Router url={generateRoutePath(authRoute.path)}>
                      {#each authRoute.subRoutes as subRoute}
                        <Route path={generateRoutePath(subRoute.path, subRoute.param)} component={subRoute.component} />
                      {/each}
                    </Router>
                  </div>
                </div>
              </Route>
            {:else}
              <Route path={generateRoutePath(authRoute.path, authRoute?.param)} component={authRoute.component} />
            {/if}
          {/each}
        </div>
        <BottomNavigation/>
      {:else}
        {#each routeListWithoutAuth as normalRoute }
          {#if normalRoute?.subRoutes?.length > 0}
              <Route path={generateRoutePath(normalRoute.path, normalRoute?.param)}>
                <div>
                  <div class="relative bg-blueGray-100 min-h-screen flex flex-col justify-between">
                    <Router url={generateRoutePath(normalRoute.path)}>
                      {#each normalRoute.subRoutes as subRoute}
                        <Route path={generateRoutePath(subRoute.path, subRoute?.param)} component={subRoute.component} />
                      {/each}
                    </Router>
                  </div>
                </div>
              </Route>
            {:else}
              <Route path={generateRoutePath(normalRoute.path, normalRoute?.param)} component={normalRoute.component} />
            {/if}
        {/each}
      {/if}
      <!-- <Route path={generateRoutePath('fill-test/:complexID/:testHash')} component={FillTest} />
      <Route path={generateRoutePath('sinav/:urlID')} component={FillTest} /> -->
    {/if}
  </Router>