<script>
      import InlineButton from "../Buttons/InlineButton.svelte";
      import LoadingOverlay from "../Form/LoadingOverlay.svelte";
      import Pagination from "../Pagination/Pagination.svelte";
    
      export let columns = [];
      export let dataSource = [];
      export let tableTitle = "";
      export let operations = null;
      export let rowOperations = null;
      export let headerButtons = [];
      export let showLoading = false;
      export let emptyCellPlaceholder = '-';
      export let showPagination = false;
      export let pagination = { page: 0, size: 10 }
      let rows = [];
      let total = 0;

      $: {
        total = dataSource ? dataSource.length : 0;
        rows = showPagination ? dataSource.slice(pagination.page * pagination.size, (pagination.page * pagination.size) + pagination.size) : dataSource;
      }
      
      function onNext() {
          pagination = { ...pagination, page: pagination.page + 1 }
      }

      function onBack() {
          pagination = { ...pagination, page: pagination.page - 1 }
      }

      function getRowByColumnKey(row, columnKey) {
        if (columnKey === 'actions') {
          return rowOperations;
        }
        if (typeof row === 'undefined' || (typeof row === 'object' && !row)) {
            return emptyCellPlaceholder;
        }
        if (columnKey.indexOf('.') >= 0) {
          var splittedColumns = columnKey.split('.');
          var shiftedRow = row[splittedColumns.shift()];
          return getRowByColumnKey(shiftedRow, splittedColumns.join('.'));
        }
        return row[columnKey] || emptyCellPlaceholder;
      }
  
      if (rows === null) {
        rows = [];
      }
    </script>
    <div
      class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
      >
    <div class="rounded-t mb-0 px-4 py-3 border-0">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3 class="font-semibold text-base text-blueGray-700">
            {tableTitle}
          </h3>
        </div>
        {#if operations || headerButtons.length > 0}
          <div class="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
            {#if operations}
              <svelte:component this={operations.component} {...operations.props}/>
            {/if}
            {#each headerButtons as headerButton}
              <InlineButton {...headerButton} />
            {/each}
          </div>
        {/if}
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <table style="border-spacing: 0px;" class="border-separate w-full min-h-200 text-sm text-left text-gray-500">
          <thead class="px-6 bg-blueGray-50 text-blueGray-500 align-middle py-3 text-xs uppercase  whitespace-nowrap font-semibold text-left">
              <tr>
                  {#each columns as column}
                      <th key={column.key} scope="col" class={`py-4 px-4${column?.type === 'actions' ? ' sticky right-0 bg-blueGray-50 border-l-2' : ''}`}>
                          {column.name}
                      </th>
                  {/each}
              </tr>
          </thead>
          <tbody>
              {#if showLoading}
                  <LoadingOverlay showOverlay fullOpacity/>
              {:else if (!rows || !rows.length)}
                  <p>No Data</p>
              {/if}
              {#each (rows || []) as row}
                <tr class="bg-white border-b">
                  {#each columns as column}
                      <td class={`${column?.type === 'actions' ? 'sticky right-0 border-l-2 ' : ''}text-black border-b-2 px-6 align-middle text-xs whitespace-nowrap p-4 text-left bg-white`}>
                          {#if column?.type === 'actions' && rowOperations}
                              <svelte:component row={row} this={rowOperations.component} {...(rowOperations.props || {})}/>
                          {:else}
                              {getRowByColumnKey(row, column.keyName)}
                          {/if}
                      </td>    
                  {/each}
                </tr>
              {/each}
          </tbody>
      </table>
    </div>
    {#if showPagination && total > pagination.size}
      <Pagination onNext={onNext} onBack={onBack} total={total} {...pagination}/>
    {/if}
    </div>
    