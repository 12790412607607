<script>
    import Modal from "../components/Modal/Modal.svelte";
    import InlineButton from "../components/Buttons/InlineButton.svelte";
    
    export let message = '';
    export let title = ''
    export let children = null;
    let visible = true;

    const onClose = () => {
        visible = false;
    }

    const onClick = () => {
        onClose();
    }

    const footer = {
        component: InlineButton,
        props: { onClick, text: "Kapat", buttonType: "delete" }
    }

</script>
<Modal
    visible={visible}
    title={title}
    onClose={onClose}
    footer={footer}
>
 {children ? children : message}
</Modal>