<script>
    export let page = 0;
    export let size = 10;
    export let total = 0;
    export let onNext = f => f;
    export let onBack = f => f;
    export let placement = 'center';
    let totalPage = 0;
    let backButtonDisabled = null;
    let nextButtonDisabled = null;

    const placements = {
        center: 'center',
        right: 'end',
        left: 'start'
    }

    $: {
        totalPage = Math.ceil(total / size);
        backButtonDisabled = page - 1 < 0;
        nextButtonDisabled = page + 1 >= totalPage
    }
</script>
<div class={`m-2 flex flex-col items-${placements[placement]}`}>
    <span class="text-sm text-gray-700">
        Toplam <span class="font-semibold text-gray-900">{total}</span> girdiden <span class="font-semibold text-gray-900">{(page * size)}</span> - <span class="font-semibold text-gray-900">{total > ((page * size) + size) ? (page * size) + size : total}</span> arasını görüntülüyorsunuz.
    </span>
    <div class="inline-flex mt-2 xs:mt-0">
        <button disabled={backButtonDisabled} on:click={onBack} class={`${backButtonDisabled ? 'disabled:opacity-50 ' : ''}bg-bo-logo-blue inline-flex items-center py-2 px-4 text-sm font-medium text-white bg-gray-800 rounded-l hover:bg-gray-900`}>
            <svg aria-hidden="true" class="mr-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clipRule="evenodd"></path></svg>
            Geri
        </button>
        <button disabled={nextButtonDisabled} on:click={onNext} class={`${nextButtonDisabled ? 'disabled:opacity-50 ' : ''}bg-bo-logo-blue inline-flex items-center py-2 px-4 text-sm font-medium text-white bg-gray-800 rounded-r border-0 border-l border-gray-700 hover:bg-gray-900`}>
            İleri
            <svg aria-hidden="true" class="ml-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
        </button>
    </div>
</div>