<script context="module">
    import { fetchWithHeaders, querifiedURL, getBaseURL } from "../utils.svelte";

    export async function dashboardLogin(username, rawPassword, isMobile) {
        const apiResp = await fetchWithHeaders(getBaseURL() + '/dashboard-users/login', {
            method: 'POST',
            body: JSON.stringify({username, password: rawPassword})
        });
        return apiResp;
    }

    export async function dashboardGetUser() {
        const apiResp = await fetchWithHeaders(getBaseURL() + '/dashboard-users/user');
        return apiResp;
    }
</script>