<script context="module">
    import { fetchWithHeaders, querifiedURL, getBaseURL } from "../utils.svelte";

    export async function getTrainingCategories() {
        const apiResp = await fetchWithHeaders(getBaseURL() + '/training-management/training-categories');
        return apiResp
    }
    
    export async function createTrainingCategory(categoryData) {
        return await fetchWithHeaders(getBaseURL() + '/training-management/training-categories', {
            method: 'POST',
            body: JSON.stringify(categoryData)
        });
    }
    
    export async function getTrainingCategoryByID(categoryID) {
        const apiResp = await fetchWithHeaders(getBaseURL() + '/training-management/training-categories/'+categoryID);
        return await apiResp;
    }
    
    export async function getTrainingCategoryWithVideos(categoryID) {
        const apiResp = await fetchWithHeaders(getBaseURL() + '/training-management/training-categories/'+categoryID+'?includeVideos=1');
        return apiResp
    }
    
    export async function updateTrainingCategoryByID(categoryID, categoryData) {
        return await fetchWithHeaders(getBaseURL() + '/training-management/training-categories/' + categoryID, {
            method: 'PUT',
            body: JSON.stringify(categoryData)
        });
    }
    
    export async function deleteTrainingCategoryByID(categoryID) {
        return await fetchWithHeaders(getBaseURL() + '/training-management/training-categories/' + categoryID, {
            method: 'DELETE',
        });
    }
    
    export async function getTrainingVideosByCategory(categoryID) {
        const apiResp = await fetchWithHeaders(getBaseURL() + '/training-management/training-categories/' + categoryID + '/videos');
        return apiResp;
    }
    
    export async function createTrainingVideo(videoData) {
        return await fetchWithHeaders(getBaseURL() + '/training-management/training-categories/' + videoData.categoryID + '/videos?type=youtube', {
            method: 'POST',
            body: JSON.stringify(videoData)
        });
    }
    
    export async function updateTrainingVideo(videoID, videoData) {
        return await fetchWithHeaders(getBaseURL() + '/training-management/training-categories/' + videoData.categoryID + '/videos/' + videoID + '?type=youtube', {
            method: 'PUT',
            body: JSON.stringify(videoData)
        });
    }
    
    export async function deleteTrainingVideo(categoryID, videoID) {
        return await fetchWithHeaders(getBaseURL() + '/training-management/training-categories/' + categoryID + '/videos/' + videoID, {
            method: 'DELETE',
        });
    }
    
    export async function getTrainingYoutubeVideo(categoryID, videoID) {
        const apiResp = await fetchWithHeaders(getBaseURL() + '/training-management/training-categories/'+categoryID+'/videos/'+videoID+'?type=youtube');
        return apiResp;
    }
</script>