<script>
    import { onMount } from 'svelte';
    import { get } from 'svelte/store'
  
    import { getUserBalanceHistory } from "../../../helpers/apiHelper.svelte";
  
    import DataTable from "../../../components/DataTable/DataTable.svelte";
    import { mobileStore } from '../../../store/mobile.store';
  
    let userID = -1;
    let balanceHistory = [];
    let totalBalance = 0;
    let isLoading = true;
  
    const tableColumns = [
      { name: "Tip", keyName: "action_meaning" },
      { name: "Kaynak", keyName: "resource_name" },
      { name: "Miktar", keyName: "amount" },
      { name: "Ek Açıklamalar", keyName: "additional_info" },
      { name: "Tarih", keyName: "earned_at" },
    ];
  
    async function intializeBalanceHistory() {
      const resp = await getUserBalanceHistory(userID);
      balanceHistory = resp?.content?.balance_history;
      totalBalance = resp?.content?.total_balance;
    }
  
    onMount(async () => {
      mobileStore.setHeader('DEM Bakiye Geçmişi');
      const storeValues = get(mobileStore);
      userID = storeValues?.userData?.id;
      await intializeBalanceHistory();
      isLoading = false;
    });
  
  </script>
<div class="pt-8">
    <DataTable
    showLoading={isLoading}
    tableTitle="BAKİYE HAREKETLERİ"
    columns={tableColumns}
    dataSource={balanceHistory}
    showPagination={false}
    />
</div>