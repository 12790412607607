<script context="module">
    import { fetchWithHeaders, querifiedURL, getBaseURL } from "../utils.svelte";

    export async function getTitles(isPlain = false) {
        const apiResp = await fetchWithHeaders(querifiedURL(getBaseURL() + '/title-management/titles', { plain: isPlain ? 1 : 0}));
        return apiResp;
    }
    
    export async function createTitle(titleData) {
        return await fetchWithHeaders(getBaseURL() + '/title-management/titles', {
            method: 'POST',
            body: JSON.stringify(titleData)
        });
    }
    
    export async function getTitleByID(titleID) {
        const apiResp = await fetchWithHeaders(getBaseURL() + '/title-management/titles/' + titleID);
        return apiResp;
    }
    
    export async function deleteTitleByID(titleID) {
        return await fetchWithHeaders(getBaseURL() + '/title-management/titles/' + titleID, {
            method: 'DELETE',
        });
    }
    
    export async function updateTitleByID(titleID, titleData) {
        return await fetchWithHeaders(getBaseURL() + '/title-management/titles/' + titleID, {
            method: 'PUT',
            body: JSON.stringify(titleData)
        });
    }
    
    export async function getTitleProperties(titleID, prop = null) {
        const apiResp = await fetchWithHeaders(getBaseURL() + '/title-management/titles/' + titleID + '/properties' + (prop ? `?prop=${prop}` : ''));
        return apiResp;
    }
</script>