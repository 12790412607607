<script>
    import { onMount } from 'svelte';
    import { getTrainingCategories } from '../../../helpers/apiHelper.svelte';
    import LoadingOverlay from '../../../components/Form/LoadingOverlay.svelte';
    import CategoryCard from '../../../components/Cards/CategoryCard.svelte'
    import { mobileStore } from '../../../store/mobile.store';

    let showLoading = true;

    let trainingCategories = [];
    onMount(async () => {
        mobileStore.setHeader('Kategori Listesi');
        const resp = await getTrainingCategories();
        trainingCategories = resp?.content;
        showLoading = false;
    });


</script>
{#if showLoading}
    <LoadingOverlay showOverlay={showLoading} />
{:else}
    <div class="container m-auto h-full min-h-screen-w-header py-4">
        <LoadingOverlay showOverlay={showLoading} />
        <div class="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-1 gap-8 py-4">
            {#each trainingCategories as category}
                <CategoryCard isMobile={true} category={category} />
            {/each}
        </div>
    </div>
{/if}