<script>
    export let showOverlay = false;
    export let fullOpacity = false;
    export let iconPosition = "center";
</script>
{#if showOverlay}
    <div style="z-index: 99999999;" class="w-full h-full flex justify-center absolute text-6xl bg-white backdrop-filter backdrop-blur-xs"
    class:items-center={iconPosition === "center"}
    class:items-start={iconPosition === "top"}
    class:bg-opacity-25={!fullOpacity}
    >
        <i class="fas fa-spinner fa-spin"></i>
    </div>
{/if}