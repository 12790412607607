<script context="module">
    import { fetchWithHeaders, getBaseURL } from "./utils.svelte";
    import {
        getDepartments,
        createDepartment,
        getDepartmentByID,
        deleteDepartmentByID,
        updateDepartmentByID,
        getParentDepartments,
        createParentDepartment,
        getParentDepartmentByID,
        deleteParentDepartmentByID,
        updateParentDepartmentByID,
        getSubDepartments,
        createSubDepartment,
        getSubDepartmentByID,
        deleteSubDepartmentByID,
        updateSubDepartmentByID,
    } from "./apiHelpers/department-management.rest.svelte";

    import {
        getCompanies,
        createCompany,
        getCompanyByID,
        deleteCompanyByID,
        updateCompanyByID,
        getCompanyProperties
    } from "./apiHelpers/company-management.rest.svelte";

    import {
        getPositions,
        createPosition,
        getPositionByID,
        deletePositionByID,
        updatePositionByID,
        getPositionProperties,
        updatePositionPriorities
    } from "./apiHelpers/position-management.rest.svelte";

    import {
        getTitles,
        createTitle,
        getTitleByID,
        deleteTitleByID,
        updateTitleByID,
        getTitleProperties
    } from "./apiHelpers/title-management.rest.svelte";

    import {
        getUserActionReports,
        getUserActionReportsByUserID,
        getVideoActionReportsByUserID,
        getTestAnswerReportsByTestID,
        getHomePageReport
    } from "./apiHelpers/event-tracker.rest.svelte";

    import {
        createTest,
        updateTestByID,
        deleteTestByID,
        getTests,
        getTestByID,
        setTestQuestions,
        getTestQuestions,
        getTestViewDataByID,
        getTestOverviewByComplexID,
        startTestWithToken,
        startTestWithURLID,
        sendComplexTestAnswers,
        sendTestAnswersWithURLID,
        getTestOverviewByURLID,
        setTestAssignees,
        getTestAssignees,
    } from "./apiHelpers/test-management.rest.svelte";

    import {
        getUserBalances,
        getUserBalanceHistory,
        createDEMEarning
    } from "./apiHelpers/dem-management.rest.svelte";

    import {
        getLoggedInUser,
        createNewAppUser,
        getAppUsers,
        deleteAppUser,
        getAppUser,
        updateAppUser,
        sendVerificationCode,
        phoneVerification,
        setFirstPassword,
    } from './apiHelpers/user.rest.svelte';

    import {
        getTrainingCategories,
        createTrainingCategory,
        getTrainingCategoryByID,
        getTrainingCategoryWithVideos,
        updateTrainingCategoryByID,
        deleteTrainingCategoryByID,
        getTrainingVideosByCategory,
        createTrainingVideo,
        updateTrainingVideo,
        deleteTrainingVideo,
        getTrainingYoutubeVideo,
    } from './apiHelpers/training-management.rest.svelte';

    import {
        mobileLogin,
        pushTraineeEvent
    } from './apiHelpers/mobile.rest.svelte';

    import {
        dashboardLogin,
        dashboardGetUser
    } from './apiHelpers/dashboard-users.rest.svelte';

    import {
        systemGetCityList,
        systemGetPredefinedValues,
    } from './apiHelpers/system.rest.svelte';

    async function login(username, rawPassword, isMobile) {
        if (isMobile) {
            return await mobileLogin(username, rawPassword);
        }
        return await dashboardLogin(username, rawPassword);
    }

    const dashboardDomain = "dashboard.blueonline.com.tr";
    const appDomain = process?.env?.DEVELOPMENT ? "localhost:5000" : "www.blueonline.com.tr";

    export {
        getDepartments,
        createDepartment,
        getDepartmentByID,
        deleteDepartmentByID,
        updateDepartmentByID,
        createNewAppUser,
        getAppUsers,
        deleteAppUser,
        getAppUser,
        updateAppUser,
        getTrainingCategories,
        createTrainingCategory,
        getTrainingCategoryByID,
        getTrainingCategoryWithVideos,
        updateTrainingCategoryByID,
        deleteTrainingCategoryByID,
        getTrainingVideosByCategory,
        createTrainingVideo,
        updateTrainingVideo,
        deleteTrainingVideo,
        getTrainingYoutubeVideo,
        login,
        dashboardGetUser,
        systemGetCityList,
        getCompanies,
        createCompany,
        getCompanyByID,
        deleteCompanyByID,
        updateCompanyByID,
        getCompanyProperties,
        getPositions,
        createPosition,
        getPositionByID,
        deletePositionByID,
        updatePositionByID,
        getPositionProperties,
        getTitles,
        createTitle,
        getTitleByID,
        deleteTitleByID,
        updateTitleByID,
        getTitleProperties,
        updatePositionPriorities,
        getParentDepartments,
        createParentDepartment,
        getParentDepartmentByID,
        deleteParentDepartmentByID,
        updateParentDepartmentByID,
        getSubDepartments,
        createSubDepartment,
        getSubDepartmentByID,
        deleteSubDepartmentByID,
        updateSubDepartmentByID,
        systemGetPredefinedValues,
        getUserActionReports,
        getUserActionReportsByUserID,
        getVideoActionReportsByUserID,
        createTest,
        updateTestByID,
        deleteTestByID,
        getTests,
        getTestByID,
        setTestQuestions,
        getTestQuestions,
        getTestViewDataByID,
        getTestOverviewByComplexID,
        startTestWithToken,
        startTestWithURLID,
        sendComplexTestAnswers,
        sendTestAnswersWithURLID,
        getTestOverviewByURLID,
        getTestAnswerReportsByTestID,
        getUserBalances,
        getUserBalanceHistory,
        createDEMEarning,
        sendVerificationCode,
        phoneVerification,
        setFirstPassword,
        getLoggedInUser,
        pushTraineeEvent,
        setTestAssignees,
        getTestAssignees,
        getHomePageReport,
        appDomain,
        dashboardDomain
    }

</script>