<script context="module">
    import { notificationStore } from '../store/store.index';
    export const notification = {
        modal: ({ title, message, children, timeOut }) => {
            notificationStore.addModal({ title, message, children, timeOut });
        },
        toast: ({ type, message, timeOut }) => {
            notificationStore.addToast({ type, message, timeOut });
        },
    }
</script>