<script>
    import { onMount } from 'svelte';
    import { getTestViewDataByID } from '../../helpers/apiHelper.svelte';

    import LoadingOverlay from "../../components/Form/LoadingOverlay.svelte";
    import RadioList from '../../components/Form/RadioList.svelte';
    import Button from '../../components/Form/Button.svelte';
    import { tweened } from 'svelte/motion';
    let timerProgress;
	  export let timerDuration;

    export let testID = -1;
    export let testData = null;
    export let onSendClick;
    let showLoading = true;
    let errorMessage;

    const questionAnswers = {};

    let interval = setInterval(() => {
      if ($timerProgress > 0) $timerProgress--;
    }, 1000);

    onMount(async () => {
      if (!testData) {
        const resp = await getTestViewDataByID(testID);
        if (resp.success && resp.content) {
            testData = resp.content;
        }
        showLoading = false;
      } else {
        showLoading = false;
      }
      timerDuration = testData.timer;
      timerProgress = tweened(timerDuration);
      initializeQuestionAnswers(testData.questions)
    });

    function initializeQuestionAnswers(questions) {
      questions.forEach((question) => {
        questionAnswers[question.id] = null;
      })
    }


    function setQuestionAnswer(question, value) {
      questionAnswers[question.id] = value;
    }

    function sendAnswers(forceSend) {
      errorMessage = null;
      Object.keys(questionAnswers).forEach( (qid) => {
        if (!questionAnswers[qid]) {
          if (forceSend) {
            questionAnswers[qid] = "Cevaplanmadı";
          } else {
            errorMessage = "Tüm soruları cevaplamanız gerekmektedir.";
          }
        }
      });
      if (errorMessage) {
        return;
      }
      if (typeof onSendClick === 'function') {
        onSendClick(questionAnswers);
      }
    }

    $: {
      if ($timerProgress === 0) {
        sendAnswers(true);
        clearInterval(interval);
      }
    }

</script>
{#if showLoading}
    <LoadingOverlay showOverlay={showLoading} />
{:else}
<div class="container mx-auto mt-4 min-h-screen-w-header">
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0 divide-y">
      <div class="flex">
        <div class="rounded-t bg-white mb-0 px-6 py-6">
          <h6 class="text-blueGray-700 text-xl font-bold">
              {testData.title}
          </h6>
          <p>
            {testData.description}
          </p>
        </div>
        <div class="rounded-t bg-white mb-0 px-6 py-6 mr-2 ml-auto center">
          {#if !isNaN($timerProgress) && !isNaN(timerDuration)}
            <h1>Sınavın bitmesine <span>{Math.floor($timerProgress / 60)}</span> dakika
              <span>{Math.floor($timerProgress % 60)}</span> saniye kaldı !</h1>
            <progress class="w-full" value={$timerProgress/timerDuration}></progress>
          {/if}
        </div>
      </div>
      <div class="px-4 lg:px-10 py-10">
        {#each testData.questions as question}
            <RadioList labelText={question.q_name} required options={question.options} onChange={ (value) => setQuestionAnswer(question, value)} />
        {/each}  
      </div>
      {#if errorMessage}
        <div class="block text-center px-4 lg:px-10 py-6 ">
          <p class="text-red-500">{errorMessage}</p>
        </div>
      {/if}
      <div class="flex justify-end flex-auto px-4 lg:px-10 py-6 ">
          <Button
            onClick={sendAnswers}
            witdhClass="w-full"
            text="Cevapları gönder"
          />
      </div>
    </div>
  </div>
{/if}

