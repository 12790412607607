<script>
    import { onMount } from 'svelte';
    import LoadingOverlay from '../Form/LoadingOverlay.svelte';
    import { systemGetPredefinedValues } from '../../helpers/apiHelper.svelte';
    import Select from '../Form/Select.svelte';
    export let defaultValues = {};
    export let options = null;
    export let initializeAtFirst = false;
    export let onChange = null;
    export let viewOnly = false;

    let values = {
        city_id: 0,
        town_id: 0,
        company_id: 0,
        parent_department_id: 0,
        department_id: 0,
        sub_department_id: 0,
        position_id: 0,
        title_id: 0,
    };
    let isInitialized = false;
    let townList = [];

    let showLoading = true;
    onMount(async () => {
        if (initializeAtFirst) {
            let predefinedValuesResp = await systemGetPredefinedValues();
            options = predefinedValuesResp.content;
        }
        showLoading = false;
    });

    function triggerChange(e) {
        if (typeof onChange === 'function') {
            onChange(values);
        }
    }
    function setTowns(selectedCityID) {
        if (options?.cities) {
            const selectedCity = options.cities.filter(city => city.id == selectedCityID)[0];
            if (selectedCity && selectedCity.towns) {
                townList = [...selectedCity.towns];
            }
        }
    }
    function triggerChangeCity(e) {
        values.town_id = 0;
        setTowns(e);
        triggerChange(e);
    }
    $: {
        if (defaultValues && Object.keys(defaultValues).length > 0 && !isInitialized) {
            isInitialized = true
            values = {...values, ...defaultValues};
            setTowns(values.city_id)
        }
    }
</script>
<div class="flex flex-wrap">
    <LoadingOverlay showLoading={showLoading} />
    <div class="w-full lg:w-3/12 px-4 mb-4">
        <Select {viewOnly} placeholder="Tümü" onChange={triggerChangeCity} bind:value={values.city_id} fieldName="city_id" labelText="İl" selectOptions={options?.cities} labelField="name" valueField="id" />
    </div>
    <div class="w-full lg:w-3/12 px-4 mb-4">
        <Select {viewOnly} placeholder="Tümü" onChange={triggerChange} bind:value={values.town_id} fieldName="town_id" labelText="İlçe" selectOptions={townList} labelField="name" valueField="id" />
    </div>
    <div class="w-full lg:w-3/12 px-4 mb-4">
        <Select {viewOnly} placeholder="Tümü" onChange={triggerChange}  bind:value={values.company_id} fieldName="company_id" labelText="Şirket" selectOptions={options?.companies} labelField="company_name" valueField="id" />
    </div>
    <div class="w-full lg:w-3/12 px-4 mb-4">
        <Select {viewOnly} placeholder="Tümü" onChange={triggerChange} bind:value={values.parent_department_id} fieldName="parent_department_id" labelText="Ana Departman" selectOptions={options?.parentDepartments} labelField="department_name" valueField="id" />
    </div>
    <div class="w-full lg:w-3/12 px-4 mb-4">
        <Select {viewOnly} placeholder="Tümü" onChange={triggerChange} bind:value={values.department_id} fieldName="department_id" labelText="Departman" selectOptions={options?.departments} labelField="department_name" valueField="id" />
    </div>
    <div class="w-full lg:w-3/12 px-4 mb-4">
        <Select {viewOnly} placeholder="Tümü" onChange={triggerChange} bind:value={values.sub_department_id} fieldName="sub_department_id" labelText="Alt Departman" selectOptions={options?.subDepartments} labelField="department_name" valueField="id" />
    </div>
    <div class="w-full lg:w-3/12 px-4 mb-4">
        <Select {viewOnly} placeholder="Tümü" onChange={triggerChange} bind:value={values.position_id} fieldName="position_id" labelText="Pozisyon" selectOptions={options?.positions} labelField="position_name" valueField="id" />
    </div>
    <div class="w-full lg:w-3/12 px-4 mb-4">
        <Select {viewOnly} placeholder="Tümü" onChange={triggerChange} bind:value={values.title_id} fieldName="title_id" labelText="Ünvan" selectOptions={options?.titles} labelField="title_name" valueField="id" />
    </div>
</div>