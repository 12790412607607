<script>
    export let labelText = "";
    export let groupName = "";
    export let value = "";
    export let required = false;
    export let options = [];

    export let onChange = null;
    let inputID = Math.random().toString(36).slice(2, 12);

    function handleChange(e) {
        if (typeof onChange === 'function') {
            onChange(e.target.value);
        }
    }
</script>

{#if labelText}
    <label class="block text-blueGray-600 text-md font-bold mb-2" for={inputID}>
        {labelText}
        {#if required}
            <span class="text-red-500">*</span>
        {/if}
    </label>
{/if}
{#each options as option }
    <div class="p-4">
        <input 
            class="border-1 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded shadow focus:outline-none focus:ring ease-linear transition-all duration-150 cursor-pointer"
            type="radio"
            id={option}
            name={labelText}
            value={option}
            on:change={handleChange}
        >
        <label class="ml-2 cursor-pointer" for={option}>{option}</label>
    </div>
{/each}