<script>
    import { onMount } from 'svelte';
    import { 
        getTestViewDataByID,
        getTestOverviewByComplexID,
        startTestWithToken,
        startTestWithURLID,
        sendComplexTestAnswers,
        sendTestAnswersWithURLID,
        getTestOverviewByURLID
    } from '../../helpers/apiHelper.svelte';

    import LoadingOverlay from '../../components/Form/LoadingOverlay.svelte';
    import Button from '../../components/Form/Button.svelte';
    import TextInput from '../../components/Form/TextInput.svelte';
    import TestRenderer from '../test-management/TestRenderer.svelte';
    import { navigate } from 'svelte-routing';

    export let complexID = -1;
    export let urlID = "";
    export let testHash;
    export let phoneNumber = "";
    export let isMobile = false;
    let testData = null;
    let showLoading = true;
    let testWithQuestions = null;
    let step = "START";
    let answerReport = {};
    let errorMessage;

    onMount(async () => {
        let resp = {};
        if (complexID > 0) {
            resp = await getTestOverviewByComplexID(complexID);
        } else if (urlID && urlID?.length === 8){
            resp = await getTestOverviewByURLID(urlID);
        }
        if (resp?.success && resp?.content) {
            testData = resp.content;
        }
        if (phoneNumber) {
            await startTest();
        }
        showLoading = false;
    });

    function onPhoneChange(value) {
        const sanitizedValue = parseInt(value);
        if (!isNaN(sanitizedValue) && `${sanitizedValue}`.length === 10) {
            phoneNumber = sanitizedValue;
        } else {
            phoneNumber = null;
        }
    }

    async function startTest() {
        console.log(urlID);
        let resp = {};
        if (complexID > -1) {
            resp = await startTestWithToken(complexID, testHash, phoneNumber);
        } else if (urlID && urlID?.length === 8) {
            resp = await startTestWithURLID(urlID, phoneNumber);
        }
        if (!resp?.success || !resp?.content) {
            //TODO :: Show error to user
        }
        testWithQuestions = resp.content;
        step = "FILL";
    }

    async function handleSendClick(questionAnswers) {
        let sendResp = {};
        if (complexID > 0) {
            sendResp = await sendComplexTestAnswers(complexID, testHash, phoneNumber, questionAnswers);
        } else if (urlID && urlID?.length === 8) {
            sendResp = await sendTestAnswersWithURLID(urlID, phoneNumber, questionAnswers);
        }
        if (!sendResp.success) {
            if (sendResp?.error_type === "DUPLICATE_ENTRY") {
                errorMessage = "Sınavı daha önce cevapladınız. Yeni cevaplarınız kaydedilmemiştir."
            }
        }
        answerReport = sendResp.content;
        step = "FINISH";
    }

    const onBackClick = () => {
        navigate('/tests');
    }
</script>
{#if showLoading}
    <LoadingOverlay showOverlay={showLoading} />
{:else if testWithQuestions && step === "FILL"}
    <TestRenderer testData={testWithQuestions} onSendClick={handleSendClick}/>
{:else if testWithQuestions && step === "FINISH"}
    <div style="width:100%;min-height:100vh; display:flex;justify-content:center;align-items:center;background-color:#EFEFEF">
        <div class="break-words shadow-lg rounded-lg bg-white border-0 divide-y max-w-lg" style="width:500px">
            <div class="rounded-t bg-white mb-0 px-6 py-6">
                <h6 class="text-blueGray-700 text-2xl font-bold">
                    {testData?.title}
                </h6>
                {#if errorMessage}
                    <p class="text-center text-xl py-4 text-red-500">
                        {errorMessage}
                    </p>
                {:else}
                    <p class="text-center text-lg pt-4">
                        Yanıtlarınız gönderilmiştir
                    </p>
                    <p class="text-center text-lg py-4 font-semibold" class:text-red-600={answerReport?.passed === false} class:text-green-600={answerReport?.passed === true}>
                        Puanınız {answerReport.user_score}/100<br>
                        {answerReport?.passed === true ? "Başarılı" : "Başarısız"}
                    </p>
                    <p class="text-center text-md pb-4">
                        Baraj puanı: <b>{answerReport.average_point}</b>
                    </p>
                    <p class="text-sm font-semibold text-green-600 py-2">Doğru Cevap Sayısı: {answerReport?.correct_count}</p>
                    <p class="text-sm font-semibold text-red-600 py-2">Yanlış Cevap Sayısı: {answerReport?.wrong_count}</p>
                    <p class="text-sm font-semibold text-gray-600 py-2">Toplam Soru Sayısı: {answerReport?.question_count}</p>
                {/if}
                <Button 
                    text='Sınav Listesine Dön'
                    witdhClass='w-full'
                    onClick={onBackClick}
                />
            </div>
        </div>
    </div>
{:else}
    <div style="width:100%;min-height:100vh; display:flex;justify-content:center;align-items:center;background-color:#EFEFEF">
        <div class="break-words shadow-lg rounded-lg bg-white border-0 divide-y max-w-lg" style="width:500px">
            <div class="rounded-t bg-white mb-0 px-6 py-6">
            <h6 class="text-blueGray-700 text-2xl font-bold">
                {testData?.title}
            </h6>
            <p>
                {testData?.description}
            </p>
            <div style="display:flex;justify-content:center;align-items:center;flex-direction:column" class="p-4">
                <TextInput
                    onChange={onPhoneChange}
                    type="phone"
                    placeholder="Telefon Numaranız"
                    required
                />
                <div class="w-full pt-4">
                    <Button
                        witdhClass="w-full"
                        onClick={startTest}
                        text="Sınavı Başlat"
                        buttonType="save"
                    />
                </div>
            </div>
            </div>
        </div>
    </div>
{/if}