<script>
    import { navigate } from 'svelte-routing';
    export let isMobile = false;
    export let test = {};
    const testID = test?.id || "";
    const title = test?.title || "";
    const questionCount = test?.questions?.length || 0 ;
    const score = test?.test_score;
    const urlID = test?.url_id;

    function onViewTestClick() {
        if (isMobile) {
            navigate('/tests/view/' + testID + '/' + urlID);
        } else {
            navigate('/test-management/tests/' + testID + '/view-test');
        }
    }

    function onEditTestClick() {
        navigate('/test-management/tests/' + testID + '/edit');
    }
</script>

<div class:opacity-50={score} class="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg min-h-44">
    <div class="flex flex-col justify-between flex-auto p-4">
        <div class="flex flex-wrap">
            <div class="relative w-full pr-4 max-w-full flex-grow flex-1">
                <span class="font-semibold text-xl text-blueGray-700">
                    {title}
                </span>
            </div>
        </div>
        <p class="relative w-full max-w-full text-sm text-blueGray-400 mt-4">
            <span class="whitespace-nowrap text-base">
                {#if isMobile}
                    {#if score}
                        <i class="fas fa-question"/>
                        <span class="ml-1">Puan: {score}</span>
                    {/if}
                {:else}
                    <i class="fas fa-question"/>
                    {#if questionCount > 0}
                        <span class="ml-1">{questionCount} Soru</span>
                    {:else}
                        <span class="ml-1">Soru Eklenmemiş</span>
                    {/if}
                {/if}
            </span>
        </p>
    </div>
    {#if isMobile}
        <div class="grid grid-cols-1 text-black text-center font-semibold text-base cursor-pointer select-none">
            <p class:user-select-none={score} class:pointer-events-none={score} class="bg-blue-100 hover:bg-blue-200 active:bg-blue-300 h-12 leading-12 shadow hover:shadow-lg ease-linear transition-all duration-150"
                data-pageName=""
                on:click={onViewTestClick}
            >
                Sınava Başla
            </p>
        </div>
    {:else}
        <div class="grid grid-cols-2 text-black text-center font-semibold text-base cursor-pointer select-none">
            <p class="bg-blue-100 hover:bg-blue-200 active:bg-blue-300 h-12 leading-12 shadow hover:shadow-lg ease-linear transition-all duration-150"
                data-pageName=""
                on:click={onViewTestClick}
            >
                Sınavı Görüntüle
            </p>
            <p class="bg-yellow-100 hover:bg-yellow-200 active:bg-yellow-300 h-12 leading-12 shadow hover:shadow-lg ease-linear transition-all duration-150"
                data-pageName="edit"
                on:click={onEditTestClick}
            >
                Sınavı Düzenle
            </p>
        </div>
    {/if}
</div>
