<script>
    import FooterSmall from "../Footers/FooterSmall.svelte";
    const registerBg2 = "/assets/img/register_bg_2.png";
</script>
<div>
    <main>
      <section class="relative w-full h-full py-40 min-h-screen">
        <div
          class="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
          style="background-image: url({registerBg2});"
        />
        <div class="container mx-auto px-4 h-full">
          <div class="flex content-center items-center justify-center h-full">
            <div class="w-full lg:w-4/12 px-4">
              <div
                class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
              >
                <div class="flex-auto px-4 lg:px-10 py-10 pt-10 rounded-t">
                  <img src="/assets/img/blueonline_logo.png" />
                  <slot />
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterSmall absolute="true" />
      </section>
    </main>
  </div>