<script>
    export let labelText = "";
    export let fieldName = "";
    export let value = "";
    export let required = false;
    export let selectOptions = [];
    export let labelField = "text";
    export let valueField = "value";
    export let placeholder = "Lütfen Seçiniz";
    export let onChange = null;
    export let viewOnly = false;
    function handleChange(e) {
        if (typeof onChange === 'function') {
            onChange(e.target.value);
        }
    }
    let inputID = Math.random().toString(36).slice(2, 12);
    $: {
        if (value <= 0) {
            value = '' + value
        }
    }
</script>

{#if labelText}
    <label
        class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
        for={inputID}
    >
        {labelText}
        {#if required}
            <span class="text-red-500">*</span>
        {/if}
    </label>
{/if}
{#if viewOnly}
    {selectOptions.filter((i1) => i1[valueField] == value)?.[0]?.[labelField] || "Tümü"}
{:else}
    <!-- svelte-ignore a11y-no-onchange -->
    <select
        class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        name={fieldName}
        id={inputID}
        {required}
        on:change={handleChange}
        bind:value>
        {#if placeholder}
            <option value="0">{placeholder}</option>
        {/if}
        {#each selectOptions as selectOption}
            <option value={selectOption[valueField]}>{selectOption[labelField]}</option>
        {/each}
    </select>
{/if}