<script>
    import Portal from '../components/Portal/Portal.svelte';
    import ModalHandler from '../notification-handlers/ModalHandler.svelte';
    import ToastHandler from '../notification-handlers/ToastHandler.svelte';
    import { notificationStore } from '../store/store.index';
    let modals = [];
    let toasts = [];
    notificationStore.subscribe(s => {
        modals = s.modals;
        toasts = s.toasts;
    });
</script>
<div class="h-0">
    {#if modals.length}
        {#each modals as modal}
            <ModalHandler {...modal} />
        {/each}
    {/if}
    {#if toasts.length}
        <Portal className="absolute top-8 right-4">
            {#each toasts as toast}
                <ToastHandler {...toast} />
            {/each}
        </Portal>
    {/if}
</div>