<script>
    import { onMount } from "svelte";
    import { getTests } from "../../../helpers/apiHelper.svelte";
    import TestCard from "../../../views/test-management/TestCard.svelte";
    let tests = [];
    onMount(async () => {
        const response = await getTests(false);
        tests = response.content
    });
</script>
<div class="py-8">
    {#each tests as test}
        <TestCard
            isMobile={true}
            test={test}
        />
    {/each}
</div>