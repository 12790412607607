<script>
    import MobileVideoCard from "./MobileVideoCard.svelte";
    import { getTrainingVideosByCategory } from '../../../helpers/apiHelper.svelte';
    import { onMount } from "svelte";
    import { mobileStore } from "../../../store/mobile.store";
    import { notification } from "../../../notification-handlers/notification.svelte";

    export let categoryID = null;
    let videoList = [];
    
    onMount(async () => {
        mobileStore.setHeader('Eğitim Listesi');
        const response = await getTrainingVideosByCategory(categoryID);
        if (response.success) {
            videoList = [...response?.content];
        } else {
            notification.modal({
                title: 'Hata !',
                message: response.message
            })
        }
    })
    
</script>
<div>
    {#each videoList as video}
        <MobileVideoCard video={video} />
    {/each}
</div>