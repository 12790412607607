<script>
    import LoginTemplate from "../../../components/LoginTemplate/LoginTemplate.svelte";
    import TextInput from "../../../components/Form/TextInput.svelte";
    import { mobileStore } from "../../../store/mobile.store";
    import { setFirstPassword } from "../../../helpers/apiHelper.svelte";
    import { get } from "svelte/store";
    import { navigate } from "svelte-routing";
    import { notification } from "../../../notification-handlers/notification.svelte";
    import { onMount } from "svelte";
    let isLoading = false;

    onMount(() => {
      notification.modal({
        title: 'Bilgilendirme !',
        message: 'Uygulamaya giriş yapacağınız şifreyi belirleyin.'
      });
    })
    const handleSubmit = async e => {
        const formData = new FormData(e.target);
        const password = formData.get('password');
        const passwordAgain = formData.get('passwordAgain');
        if (password !== passwordAgain) {
            notification.modal({
              title: 'Hata !',
              message: 'Şifreler aynı olmalı !'
            });
            return;
        }
        const { mobileNumber, verificationCode } = get(mobileStore);
        const response = await setFirstPassword(mobileNumber, verificationCode, password);
        if (response.success) {
          navigate('/login');
        } else {
          notification.modal({
            title: 'Hata !',
            message: response.message
          });
        }
    }
</script>
<LoginTemplate>
    <form id="set-password-form" on:submit|preventDefault={handleSubmit}>
        <div class="mb-3 w-full flex justify-center items-center">
        </div>
        <div class="relative w-full mb-3">
            <TextInput
              fieldName="password"
              labelText="Şifre"
              required
              placeholder="*******"
            />
        </div>
        <div class="relative w-full mb-3">
          <TextInput
            fieldName="passwordAgain"
            labelText="Şifre (Doğrulama)"
            required
            placeholder="*******"
          />
        </div>
  
        <div class="text-center mt-6">
          <button
            class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150 disabled:opacity-50"
            type="submit"
            disabled={isLoading}
            form="set-password-form"
          >
            Olustur
          </button>
        </div>
      </form>
</LoginTemplate>