<script>
    import { navigate } from "svelte-routing";
    import LoginTemplate from "../../../components/LoginTemplate/LoginTemplate.svelte";
    import TextInput from "../../../components/Form/TextInput.svelte";
    import { sendVerificationCode } from "../../../helpers/apiHelper.svelte";
    import { mobileStore } from "../../../store/mobile.store";
    import { notification } from "../../../notification-handlers/notification.svelte";
    let isLoading = false;

    async function handleSubmit(e) {
      isLoading = true;
      const formData = new FormData(e.target);
      mobileStore.setMobileNumber(formData.get('phone'));
      const response = await sendVerificationCode(formData.get("phone"));
  
      if (!response?.success) {
        notification.modal({
          title: 'Hata !',
          message: response.message
        })
      } else {
        navigate('/signup/verification');
      }
    }
  </script>
  
  <LoginTemplate>
    <form id="signup-form" on:submit|preventDefault={handleSubmit}>
      <div class="mb-3 w-full flex justify-center items-center">
      </div>
      <div class="relative w-full mb-3">
        <TextInput
          fieldName="phone"
          labelText="Telefon Numarası"
          required
          placeholder="5555555555"
        />
      </div>

      <div class="text-center mt-6">
        <button
          class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150 disabled:opacity-50"
          type="submit"
          disabled={isLoading}
          form="signup-form"
        >
          DOGRULA
        </button>
      </div>
    </form>
  </LoginTemplate>
  