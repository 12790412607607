import MobileApp from "./MobileApp.svelte";
import InstallApp from "./InstallApp.svelte";
function isPwa() {
  return ["fullscreen", "standalone", "minimal-ui"].some(
      (displayMode) => window.matchMedia('(display-mode: ' + displayMode + ')').matches
  );
}
let app = null;
if (false && !isPwa() && !window?.location?.href?.includes('forceOpen=1')) {
  app = new InstallApp({
    target: document.getElementById("app"),
  });
} else {
  app = new MobileApp({
    target: document.getElementById("app"),
  });
}
export default app;
