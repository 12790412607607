<script>
    export let labelText = "";
    export let required = false;
    export let value = "";
    export let type = "text";
    export let fieldName = "";
    export let placeholder = "";
    export let onChange = null;
    let inputID = Math.random().toString(36).slice(2, 12);

    function setType(element) {
        element.type = type;
    }
    
    function handleChange() {
        if (typeof onChange === 'function') {
            onChange(value);
        }
    }

</script>
{#if labelText}
    <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" for={inputID}>
        {labelText}
        {#if required}
            <span class="text-red-500">*</span>
        {/if}
    </label>
{/if}
<input
    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
    {placeholder}
    name={fieldName}
    id={inputID}
    {required}
    bind:value
    use:setType
    on:change={handleChange}
/>
