<script>
    import { navigate } from 'svelte-routing';
    export let text = "";
    export let color = "bg-indigo-500";
    export let activeColor = "active:bg-indigo-600";
    export let hoverColor = "hover:bg-indigo-600";
    export let textColor = "text-white";
    export let nextRoute = "";
    export let leftIcon = "";
    export let rightIcon = "";
    export let buttonType = "";
    export let textSize = "text-xs";
    export let onClick = null;

    if (buttonType === "cancel") {
        color = "bg-gray-500";
        hoverColor = "hover:bg-gray-600";
        activeColor = "active:bg-gray-700";
    } else if (buttonType === "delete") {
        color = "bg-red-500";
        hoverColor = "hover:bg-red-600";
        activeColor = "active:bg-red-700";
    } else if (buttonType === "edit") {
        color = "bg-yellow-400";
        hoverColor = "hover:bg-yellow-500";
        activeColor = "active:bg-yellow-600";
    } else if (buttonType === "success") {
        color = "bg-green-500";
        hoverColor = "hover:bg-green-600";
        activeColor = "active:bg-green-700";
    } else if (buttonType === "info") {
        color = "bg-blue-500";
        hoverColor = "hover:bg-blue-600";
        activeColor = "active:bg-blue-700";
    } else if (buttonType === "new-option") {
        textColor = "text-black";
        color = "bg-white";
        hoverColor = "hover:bg-gray-200";
        activeColor = "active:bg-gray-300";
    }

    function handleClick() {
        if (typeof onClick === 'function') {
            onClick();
            return;
        }
        if (nextRoute) {
            navigate(nextRoute, { replace: false });
        }
    }
</script>

<button
    class="{color} {hoverColor} {activeColor} {textColor} {textSize} font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 inline-button"
    on:click={handleClick}
    type="button"
>
    {#if leftIcon}
        <i class="{leftIcon}"></i>
    {/if}
    {text}
    {#if rightIcon}
        <i class="{rightIcon}"></i>
    {/if}
</button>
