<script>
    import { onMount } from 'svelte';
    import { getTrainingYoutubeVideo, pushTraineeEvent } from '../../../helpers/apiHelper.svelte';
    import Youtube from './Youtube.svelte';
    export let categoryID = null;
    export let videoID = null;
    let youtubeID = null;
    let playerState = null;
    let watchEvents = {
        watch0: false,
        watch20: false,
        watch40: false,
        watch60: false,
        watch80: false,
        watch100: false
    }
    
    onMount(async () => {
        await pushTraineeEvent('openVideo', videoID);
        const response = await getTrainingYoutubeVideo(categoryID, videoID);
        if (response.success) {
            youtubeID = response.content.youtube_id;
        }
    });

    const handleWatchEvents = async (currentTime, duration) => {
        let x = currentTime * 100 / duration;
        let event = null;
        switch (true) {
            case x > 95:
                event = "watch100";
                break;
            case x >= 80 && x < 95:
                event = "watch80";
                break;
            case x >= 60 && x < 80:
                event = "watch60";
                break;
            case x >= 40 && x < 60:
                event = "watch40";
                break;
            case x >= 20 && x < 40:
                event = "watch20";
                break;
            default: break;
        }
        if (event && !watchEvents[event]) {
            await pushTraineeEvent(event, videoID);
            watchEvents[event] = true;
        }
    }

    const handleWatchOperations = e => {
        if (playerState === 1) {
            const [currentTime, duration] = e.detail;
            handleWatchEvents(currentTime, duration);
        }
    }

    const onStateChange = async e => {
        const { data } = e.detail;
        playerState = data;
        if (playerState === 1 && !watchEvents.watch0) {
            await pushTraineeEvent('watch0', videoID);
            watchEvents.watch0 = true;
        }
    }
</script>
{#if youtubeID}
    <div class="video-wrapper">
        <Youtube
            youtubeID={youtubeID}
            on:currentPlayTime={handleWatchOperations}
            on:stateChange={onStateChange}
        />
    </div>
{/if}