import { writable } from "svelte/store";

const createMobileStore = () => {
    const { subscribe, set, update } = writable({
        header: '',
        mobileNumber: null,
        verificationCode: null,
        userData: null
    });
    
    return {
        subscribe,
        setHeader: header => update(s => {
            s.header = header;
            return s;
        }),
        setMobileNumber: mobileNumber => update(s => {
            s.mobileNumber = mobileNumber;
            return s;
        }),
        setVerificationCode: verificationCode => update(s => {
            s.verificationCode = verificationCode;
            return s;
        }),
        setUserData: userData => update(s => {
            s.userData = userData;
            return s;
        }),
        reset: () => set({
            mobileNumber: null,
            userData: null
        })
    }
};

export const mobileStore = createMobileStore();