<script>
    import { navigate } from 'svelte-routing';
    export let isMobile = false;
    export let category = {};
    const categoryID = category?.id || "";
    const name = category?.name || "";
    const iconColor = category?.color_code || "FFFFFF";
    const videoCount = category?.video_count || 0 ;

    function onFooterButtonClick(e) {
        const page = e.target.getAttribute('data-pageName');
        if (isMobile) {
            navigate('/trainings/categories/' + categoryID + '/videos');
        } else {
            navigate('/training-management/categories/' + categoryID + '/' + page);
        }
    }
</script>

<div class="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
    <div class="flex flex-col justify-between flex-auto p-4">
        <div class="flex flex-wrap">
            <div class="relative w-full pr-4 max-w-full flex-grow flex-1">
                <span class="font-semibold text-xl text-blueGray-700">
                    {name}
                </span>
            </div>
            <div class="relative w-auto pl-4 flex-initial">
                <div style="background-color: #{iconColor}" class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full">
                </div>
            </div>
        </div>
        <p class="relative w-full max-w-full text-sm text-blueGray-400 mt-4">
            <span class="whitespace-nowrap text-base">
                <i class="fas fa-video"/>
                {#if videoCount > 0}
                    <span class="ml-1">{videoCount} Eğitim Videosu</span>
                {:else}
                    <span class="ml-1">Eğitim videosu eklenmemiş</span>
                {/if}
            </span>
        </p>

    </div>
    <div class="grid grid-cols-{isMobile ? '1' : '2'} text-black text-center font-semibold text-base cursor-pointer select-none">
        <p class="bg-blue-100 hover:bg-blue-200 active:bg-blue-300 h-12 leading-12 shadow hover:shadow-lg ease-linear transition-all duration-150"
            data-pageName="videos"
            on:click={onFooterButtonClick}
        >
            Videolar
        </p>
        {#if !isMobile}
            <p class="bg-yellow-100 hover:bg-yellow-200 active:bg-yellow-300 h-12 leading-12 shadow hover:shadow-lg ease-linear transition-all duration-150"
                data-pageName="edit"
                on:click={onFooterButtonClick}
            >
                Kategoriyi Düzenle
            </p>
        {/if}
    </div>
</div>
