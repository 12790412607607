<script context="module">
    import { fetchWithHeaders, querifiedURL, getBaseURL } from "../utils.svelte";

    export async function systemGetCityList() {
        const apiResp = await fetchWithHeaders(getBaseURL() + '/system/cities');
        return apiResp;
    }

    export async function systemGetPredefinedValues() {
        const apiResp = await fetchWithHeaders(getBaseURL() + '/system/predefined-values');
        return apiResp;
    }
</script>