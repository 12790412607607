<script context="module">
    import { fetchWithHeaders, querifiedURL, getBaseURL } from "../utils.svelte";

    export async function getUserBalances() {
        const apiResp = await fetchWithHeaders(querifiedURL(getBaseURL() + '/users/dem-balances'));
        return apiResp;
    }
    export async function getUserBalanceHistory(userID) {
        const apiResp = await fetchWithHeaders(querifiedURL(getBaseURL() + '/user/' + userID + '/dem-balance-history'));
        return apiResp;
    }
    export async function createDEMEarning(userID, action_type, amount, additional_info, currentAdminID) {
        const earningData = {
            user_id: userID,
            action_type,
            amount,
            additional_info,
            action_resource_id: currentAdminID
        };
        const apiResp = await fetchWithHeaders(querifiedURL(getBaseURL() + '/dem-management/create-earning'),{
            method: 'POST',
            body: JSON.stringify(earningData)
        });
        return apiResp;
    }
</script>