<script>
    import { onMount } from "svelte";
    import { get } from 'svelte/store';
    import { link } from "svelte-routing";
    import { mobileStore } from "../../store/mobile.store";
  let menuItems = [
  {
    iconClass: "fa-graduation-cap",
    link: "/trainings/categories",
  },
  {
    iconClass: "fa-question",
    link: "/tests",
  }
  ];
  onMount( () => {
    const storeValues = get(mobileStore);
    const userData = storeValues.userData;
    menuItems.push( {
      imgSrc: "/assets/img/dem_logo.png",
      link:"/dem/balance-history",
      backgroundColor: "bg-bo-logo-orange",
      text: userData?.dem_coin || "0"
    });
    menuItems = [...menuItems];
  })
</script>
<div class="flex justify-center bottom-0 w-full h-10 z-10 bg-bo-logo-blue">
  {#each menuItems as menuItem, index}
    <a use:link href={menuItem.link}
      class:border-r-2={index + 1 !== menuItems.length}
      class:border-white={index + 1 !== menuItems.length}
      class="flex flex-1 items-center justify-center text-sm uppercase py-3 font-bold text-white {menuItem?.backgroundColor}"
      >
      {#if menuItem?.imgSrc}
        <span class="flex items-center justify-center">
          <img src={menuItem.imgSrc} class="w-12 bg-white p-1 rounded" alt="dem coin">
          <span class="ml-2 text-lg">{menuItem?.text || ""}</span>
        </span>
      {:else}
        <i class="fas {menuItem.iconClass} text-sm text-white"></i>
      {/if}
    </a>
  {/each}
</div>