<script context="module">
    import { fetchWithHeaders, querifiedURL, getBaseURL } from "../utils.svelte";

    export async function createTest(testData) {
        return await fetchWithHeaders(getBaseURL() + '/test-management', {
            method: 'POST',
            body: JSON.stringify(testData)
        });
    }

    export async function updateTestByID(testID, testData) {
        return await fetchWithHeaders(getBaseURL() + '/test-management/test/' + testID, {
            method: 'PUT',
            body: JSON.stringify(testData)
        });
    }

    export async function deleteTestByID(testID) {
        return await fetchWithHeaders(getBaseURL() + '/test-management/test/' + testID, {
            method: 'DELETE',
        });
    }

    export async function getTests(withQuestions = true) {
        let endpoint = "/test-management/tests";
        if (!withQuestions) {
            endpoint += "?withQuestions=0";
        }
        const apiResp = await fetchWithHeaders(getBaseURL() + endpoint);
        return apiResp;
    }

    export async function getTestByID(testID) {
        const apiResp = await fetchWithHeaders(getBaseURL() + '/test-management/tests/' + testID);
        return apiResp;
    }

    export async function getTestViewDataByID(testID) {
        const apiResp = await fetchWithHeaders(getBaseURL() + '/test-management/tests/' + testID + '/view-test');
        return apiResp;
    }

    export async function setTestQuestions(testID, questions) {

    }

    export async function getTestQuestions(testID) {
        const apiResp = await fetchWithHeaders(getBaseURL() + '/test-management/test/' + testID + '/questions');
        return apiResp;
    }

    export async function getTestOverviewByComplexID(complexTestID) {
        const apiResp = await fetchWithHeaders(getBaseURL() + '/test-management/test-overview/' + complexTestID);
        return apiResp;
    }
    
    export async function getTestOverviewByURLID(urlID) {
        const apiResp = await fetchWithHeaders(getBaseURL() + '/test-management/test-overview/url/' + urlID);
        return apiResp;
    }

    export async function startTestWithToken(complexID, testHash, phoneNumber) {
        return await fetchWithHeaders(getBaseURL() + '/test-management/start-test', {
            method: 'POST',
            body: JSON.stringify({
                complexTestID: complexID,
                testToken: testHash,
                phoneNumber
            })
        });
    }

    export async function startTestWithURLID(urlID, phoneNumber) {
        return await fetchWithHeaders(getBaseURL() + '/test-management/start-test/url', {
            method: 'POST',
            body: JSON.stringify({
                urlID,
                phoneNumber
            })
        });
    }

    export async function sendComplexTestAnswers(complexID, testHash, phoneNumber, questionAnswers) {
        return await fetchWithHeaders(getBaseURL() + '/test-management/send-answers/' + complexID, {
            method: 'POST',
            body: JSON.stringify({
                testToken: testHash,
                phoneNumber,
                questionAnswers
            })
        });
    }

    export async function sendTestAnswersWithURLID(urlID, phoneNumber, questionAnswers) {
        return await fetchWithHeaders(getBaseURL() + '/test-management/send-answers/url/' + urlID, {
            method: 'POST',
            body: JSON.stringify({
                phoneNumber,
                questionAnswers
            })
        });
    }

    export async function setTestAssignees(testID, assignees) {
        return await fetchWithHeaders(getBaseURL() + '/test-management/test/' + testID + '/assignments', {
            method: 'POST',
            body: JSON.stringify({assignees})
        });
    }

    export async function getTestAssignees(testID) {
        const apiResp = await fetchWithHeaders(getBaseURL() + '/test-management/test/' + testID + '/assignments');
        return apiResp;
    }
</script>