<script>
    import { link } from "svelte-routing";
    import { mobileStore } from "../../store/mobile.store";
    let header = '';
    mobileStore.subscribe(store => {
        header = store.header;
    })
</script>
<div class="h-10 bg-bo-logo-blue text-center text-white text-xl font-semibold flex items-center justify-center">
    {#if history.length > 3}
        <div class="absolute left-4">
            <i on:click={() => history.back()} class="fa fa-arrow-left" aria-hidden="true"></i>
        </div>
    {/if}
    {header}
    <a use:link href={"/profile/view"}
      class="absolute right-4 font-bold text-white"
      >
      <i class="fas fa-user text-sm text-white"></i>
    </a>
</div>