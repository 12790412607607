<script context="module">
    import { fetchWithHeaders, querifiedURL, getBaseURL } from "../utils.svelte";

    export async function getLoggedInUser() {
        const apiResp = await fetchWithHeaders(getBaseURL() + '/user');
        return apiResp;
    }

    export async function createNewAppUser(userData) {
        const apiResp = await fetchWithHeaders(getBaseURL() + '/users', {
            method: 'POST',
            body: JSON.stringify(userData)
        });
        return apiResp;
    }
    
    export async function getAppUsers(forSelect = false) {
        let endpoint = '/users';
        if (forSelect) {
            endpoint += '?forSelect=1';
        }
        const apiResp = await fetchWithHeaders(getBaseURL() + endpoint);
        return apiResp;
    }
    
    export async function deleteAppUser(userID) {
        return await fetchWithHeaders(getBaseURL() + '/users/' + userID, {
            method: 'DELETE',
        });
    }
    
    export async function getAppUser(userID) {
        const apiResp = await fetchWithHeaders(getBaseURL() + '/user/' + userID);
        return apiResp;
    }
    
    export async function updateAppUser(userID, userData) {
        return await fetchWithHeaders(getBaseURL() + '/users/' + userID, {
            method: 'PUT',
            body: JSON.stringify(userData)
        });
    }

    export async function sendVerificationCode(phone) {
        return await fetchWithHeaders(getBaseURL() + '/send-verification-code', {
            method: 'POST',
            body: JSON.stringify({ phone })
        })
    }

    export async function phoneVerification(phone, code) {
        return await fetchWithHeaders(getBaseURL() + '/phone-verification', {
            method: 'POST',
            body: JSON.stringify({ phone, code })
        })
    }

    export async function setFirstPassword(phone, code, password) {
        return await fetchWithHeaders(`${getBaseURL()}/user/${phone}/first-password`, {
            method: 'POST',
            body: JSON.stringify({ code, password })
        })
    }
</script>