<script context="module">
    import { fetchWithHeaders, querifiedURL, getBaseURL } from "../utils.svelte";

    export async function getCompanies(isPlain = false) {
        const apiResp = await fetchWithHeaders(querifiedURL(getBaseURL() + '/company-management/companies', { plain: isPlain ? 1 : 0}));
        return apiResp;
    }
    
    export async function createCompany(companyData) {
        return await fetchWithHeaders(getBaseURL() + '/company-management/companies', {
            method: 'POST',
            body: JSON.stringify(companyData)
        });
    }
    
    export async function getCompanyByID(companyID) {
        const apiResp = await fetchWithHeaders(getBaseURL() + '/company-management/companies/' + companyID);
        return apiResp;
    }
    
    export async function deleteCompanyByID(companyID) {
        return await fetchWithHeaders(getBaseURL() + '/company-management/companies/' + companyID, {
            method: 'DELETE',
        });
    }
    
    export async function updateCompanyByID(companyID, companyData) {
        return await fetchWithHeaders(getBaseURL() + '/company-management/companies/' + companyID, {
            method: 'PUT',
            body: JSON.stringify(companyData)
        });
    }
    
    export async function getCompanyProperties(companyID, prop = null) {
        const apiResp = await fetchWithHeaders(getBaseURL() + '/company-management/companies/' + companyID + '/properties' + (prop ? `?prop=${prop}` : ''));
        return apiResp;
    }
</script>