<script>
    import { onMount } from 'svelte';
    import { get } from 'svelte/store';
    import {
      getAppUser,
      systemGetCityList,
      getParentDepartments,
      getDepartments,
      getSubDepartments,
      getCompanies,
      getPositions,
      getTitles
    } from '../../../helpers/apiHelper.svelte';
    import PredefinedValueSelect from "../../../components/PredefinedValueSelect/index.svelte";
    import LoadingOverlay from "../../../components/Form/LoadingOverlay.svelte";
    import { mobileStore } from '../../../store/mobile.store';
    import InlineButton from '../../../components/Buttons/InlineButton.svelte';
    import { removeCookie } from '../../../helpers/utils.svelte';
    import { notification } from '../../../notification-handlers/notification.svelte';
    
    let userID = -1;

    let showLoading = false;
    let userDetails = null;
    let workValues = {
      cityList: [],
      parentDepartmentList: [],
      departmentList: [],
      subDepartmentList: [],
      companyList: [],
      positionList: [],
      titleList: [],
      companyList: [],
    };

    async function initalizeWorkValues() {
      const cityListResp = await systemGetCityList();
      if (!cityListResp?.success) {
          notification.modal({
            title: 'Hata !',
            message: cityListResp.message
          });
          return;
      }
      workValues.cityList = cityListResp.content || [];
    
      const parentDepartmentsResp = await getParentDepartments();
      if (!parentDepartmentsResp?.success) {
          notification.modal({
            title: 'Hata !',
            message: parentDepartmentsResp.message
          });
          return;
      }
      workValues.parentDepartmentList = parentDepartmentsResp.content || [];
    
      const departmentsResp = await getDepartments();
      if (!departmentsResp?.success) {
          notification.modal({
            title: 'Hata !',
            message: departmentsResp.message
          });
          return;
      }
      workValues.departmentList = departmentsResp.content || [];
    
      const subDepartmentsResp = await getSubDepartments();
      if (!subDepartmentsResp?.success) {
          notification.modal({
            title: 'Hata !',
            message: subDepartmentsResp.message
          });
          return;
      }
      workValues.subDepartmentList = subDepartmentsResp.content || [];
      
      const companiesResp = await getCompanies();
      if (!companiesResp?.success) {
          notification.modal({
            title: 'Hata !',
            message: companiesResp.message
          });
          return;
      }
      workValues.companyList = companiesResp.content || [];
      
      const positionsResp = await getPositions();
      if (!positionsResp?.success) {
          notification.modal({
            title: 'Hata !',
            message: positionsResp.message
          });
          return;
      }
      workValues.positionList = positionsResp.content || [];
      
      const titlesResp = await getTitles();
      if (!titlesResp?.success) {
          notification.modal({
            title: 'Hata !',
            message: titlesResp.message
          });
          return;
      }
      workValues.titleList = titlesResp.content || [];
      workValues = {...workValues};
    }
    
    
    onMount(async () => {
      mobileStore.setHeader('Profil')
      showLoading = true;
      await initalizeWorkValues();
      const storeValues = get(mobileStore)
      userID = storeValues.userData.id;
      if (userID > -1) {
        const appUser = await getAppUser(userID);
        if (!appUser?.success) {
          notification.modal({
            title: 'Hata !',
            message: appUser.message
          });
        }
        userDetails = appUser?.content?.user;
      }
      showLoading = false;
    });

    const onLogoutClick = () => {
      removeCookie('bluc');
      window.location.reload();
    }
    </script>
    
    <div class="container mx-auto mt-4">
      <div
        class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0 divide-y"
      >
        <LoadingOverlay iconPosition="top" showOverlay={showLoading}/>
        <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form id="new-user-form" on:submit|preventDefault>
            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Uygulamaya Giriş Bilgileri
            </h6>
            <div class="flex flex-wrap">
              <div class="w-full px-4 mb-4">
                <span class="block uppercase text-blueGray-600 text-xs font-bold mb-2">Telefon Numarası</span>
                {userDetails?.phone || "-"}
              </div>
            </div>
    
            <hr class="mt-6 border-b-1 border-blueGray-300" />
    
            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Kişisel Bilgiler
            </h6>
    
            <div class="flex flex-wrap">
              <div class="w-full px-4 mb-4">
                <span class="block uppercase text-blueGray-600 text-xs font-bold mb-2">Ad Soyad</span>
                {userDetails?.fullname || "-"}
              </div>
              <div class="w-full px-4 mb-4">
                <span class="block uppercase text-blueGray-600 text-xs font-bold mb-2">Email</span>
                {userDetails?.email || "-"}
              </div>
              <div class="w-full lg:w-6/12 px-4 mb-4">
                <span class="block uppercase text-blueGray-600 text-xs font-bold mb-2">Doğum Tarihi</span>
                {userDetails?.personal_information?.birth_date || "-"}
              </div>
              <div class="w-full lg:w-6/12 px-4 mb-4">
                <span class="block uppercase text-blueGray-600 text-xs font-bold mb-2">Cinsiyet</span>
                {userDetails?.personal_information?.gender === "M" ? "Erkek" : "Kadın"}
              </div>
              <div class="w-full px-4 mb-4">
                <span class="block uppercase text-blueGray-600 text-xs font-bold mb-2">İşe Başlama Tarihi</span>
                {userDetails?.personal_information?.onboarding_date || "-"}
              </div>
              <div class="w-full px-4 mb-4">
                <span class="block uppercase text-blueGray-600 text-xs font-bold mb-2">Sicil Numarası</span>
                {userDetails?.personal_information?.employee_number || "-"}
              </div>
            </div>
    
            <hr class="mt-6 border-b-1 border-blueGray-300" />
    
            <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Çalışma Bilgileri
            </h6>
            <div class="flex flex-wrap">
              <PredefinedValueSelect viewOnly onChange={(values) => console.log(values)} initializeAtFirst={true} defaultValues={userDetails?.work_information} />
            </div>
          </form>
        </div>
      </div>
      <div class="flex-col w-full flex justify-center">
        <InlineButton text="Çıkış Yap" buttonType="delete" onClick={() => onLogoutClick()} /> 
      </div>
    </div>
    