<script context="module">
    export function setCookie(cookieName, cookieData) {
        document.cookie =
            cookieName +
            "=" +
            cookieData +
            ';expires=Thu, 31 Dec 2099 00:00:00 UTC; path="/"';
    }

    export function removeCookie(cookieName) {
        document.cookie = cookieName+'=; Max-Age=-99999999;';  
    }

    export function getCookie(cookieName) {
        var cookies = document.cookie.split(";");
        if (cookies?.length > 0) {
            for (let cookieStr of cookies) {
                var cookieSelector = cookieName + "=";
                if (cookieStr.trim().startsWith(cookieSelector.trim())) {
                    return cookieStr.replaceAll(cookieSelector, "");
                }
            }
        }
        return null;
    }

    const responseTypes = {
        "application/json": (response) => response.json(),
        "text/plain": (response) => response.text(),
    };
    // TODO: UPDATE FOR POST REQUESTS
    // const updateApiCache = async (url = '', method = '') => {
    //     if (method === 'POST')
    // }

    const cacheKeys = [
        'dashboard-users/user',
        'system/predefined-values'
    ]

    export async function fetchWithHeaders(...args) {
        const cacheKey = cacheKeys.filter(item => args[0].includes(item))?.[0];
        if (cacheKey && window.apiCache?.[cacheKey]) {
            return window.apiCache[cacheKey];
        }
        const requestHeaders = {
            Authorization: getCookie("bluc"),
        };
        if (args[1]) {
            args[1].headers = requestHeaders;
        } else {
            args[1] = {
                headers: requestHeaders,
            };
        }
        try {
            const fetchResponse = await fetch(...args);
            if (fetchResponse) {
                const responseType = fetchResponse.headers.get("Content-Type");
                const responsePromise = responseTypes[responseType](fetchResponse) || fetchResponse;
                const response = await responsePromise;
                if (cacheKey) {
                    window.apiCache = {...window?.apiCache, [cacheKey]: response}
                }
                return response;
            }
            return fetchResponse;
        } catch (err) {
            console.log(`API Error. URL: ${args[0]} `, err);
        }
    }

    export const elementInViewPort = (element = null) => {
        if (element) {
            const bounding = element.getBoundingClientRect();
            if (bounding.top >= 0 && bounding.left >= 0 && bounding.right <= window.innerWidth && bounding.bottom <= window.innerHeight) {
                return true;
            } else {
                return false;
            }
        }
    }

    export function querifiedURL(url, params) {
        if (typeof params === "object" && params) {
            params = new URLSearchParams(params);
            return `${url}?${params}`;
        }
        return url;
    }

    export function getBaseURL() {
        let baseURL = "https://api.blueonline.com.tr";
        if (window.location.origin.indexOf("localhost") >= 0) {
            baseURL = "https://api-dev.reactivers.com";
        }
        return baseURL;
    }
</script>
