<script>
    import { navigate } from 'svelte-routing';
    export let video = null;
    let categoryID = video.category_id;
    let videoID = video.id;
    let thumbnailURL = video.thumbnail_url;
    let title = video.title;
    let description = video.description;
    let duration = video.duration;

    const onVideoClick = () => {
        navigate(`/trainings/categories/${categoryID}/videos/${videoID}`);
    }

</script>
<div class:opacity-50={video?.progress === 100} class="flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg mt-4">
    <div class="flex flex-col justify-between flex-auto p-4">
        <div class="lg:flex md:grid md:grid-cols-2 w-full pr-4 max-w-full">
            <div on:click={onVideoClick} class="lg:flex-none lg:flex-grow-0 lg:flex-shrink-0 flex justify-center items-center h-full">
                <img class="w-72" src="{thumbnailURL}" alt="{title}" />
            </div>
            <div class="lg:flex-1 lg:pl-4">
                <div class="flex justify-between items-center">
                    <span class="font-semibold text-xl text-blueGray-700">{title}</span>
                </div>
                <div class="text-base text-blueGray-700">
                    {@html description.replaceAll('\n','<br />')}
                </div>
            </div>
        </div>
        <p class="relative w-full max-w-full text-sm text-blueGray-400 mt-4">
            <span class="whitespace-nowrap text-base">
                <i class="fas fa-clock"/>
                {#if duration > 0}
                    <span class="ml-1">{parseInt(duration/60)} Dakika {parseInt(duration%60)} Saniye</span>
                {:else}
                    <span class="ml-1">Belirsiz</span>
                {/if}
            </span>
        </p>
        <p class="relative w-full max-w-full text-sm text-blueGray-400 mt-4">
            {#if video?.progress > 0}
                <i class="fas fa-percent" />
                <span class="ml-1">{parseInt(video.progress)} izlendi</span>
            {:else}
                <span class="ml-1">Henüz izlenmedi</span>
            {/if}
        </p>
    </div>
</div>