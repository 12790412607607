<script>
    import Youtube from "./mobile-training-management/Youtube.svelte";
    let player = null;
</script>
<div>
    <Youtube 
        on:stateChange={e => console.log(e)}
        on:currentPlayTime={e => console.log(e, 'currentPlayTime')}
        bind:this={player} 
    />
</div>