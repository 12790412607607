<script context="module">
    var tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
</script>

<script>
    import { createEventDispatcher, onDestroy, onMount } from "svelte";
    export let youtubeID = null;
    console.log(youtubeID);
    let player;
    let divId = "player_" + parseInt(Math.random() * 109999);
    let intervalID = null;
    
    function createPlayer() {
        const domElement = document.documentElement;
        const width = domElement.offsetWidth;
        const height = domElement.offsetHeight - 80;
        player = new YT.Player(divId, {
            width,
            height,
            videoId: youtubeID,
            playerVars: {
                autoplay: 0
            },
            events: {
                onReady: onPlayerReady,
                onError: onPlayerError,
                onStateChange: onPlayerStateChange,
            }
        });
        return () => player.destroy();
    }

    onMount(() => {
        if (youtubeID) {
            createPlayer();
        }
    });

    const dispatch = createEventDispatcher();

    function onPlayerReady() {
        dispatch('getDuration', player.getDuration());
        intervalID = setInterval(() => {
            dispatch("currentPlayTime", [player.getCurrentTime(), player.getDuration()]);
        }, 1000);
    }

    function onPlayerError(event) {
        dispatch('error', event);
    }
    
    function onPlayerStateChange(event) {
        dispatch('stateChange', event);
        switch (event.data) {
            case 0:
                dispatch('end', event);
                break;

            case 1:
                dispatch('play', event);
                break;

            case 2:
                dispatch('pause', event);
                break;

            default: break;
        }
    }
    onDestroy(() => {
        try {
            if (intervalID) {
                clearInterval(intervalID);
                console.log("Interval ",intervalID," cleaned up");
            }
        } catch(err) {
            console.log("Error while clearing interval:", err);
        }
    })
</script>
<div bind:this={player} id={divId} />