<script context="module">
    import { fetchWithHeaders, querifiedURL, getBaseURL } from "../utils.svelte";

    export async function mobileLogin(phone, password) {
        return await fetchWithHeaders(getBaseURL() + '/login', {
            method: 'POST',
            body: JSON.stringify({ phone, password })
        });
    }

    export async function pushTraineeEvent(event, videoID) {
        const apiResp = await fetchWithHeaders(getBaseURL() + '/event-tracker/log-trainee', {
            method: 'POST',
            body: JSON.stringify({
                event_type: event,
                resource_id: videoID
            })
        });
        return apiResp;
    }
</script>