<script context="module">
    import { fetchWithHeaders, querifiedURL, getBaseURL } from "../utils.svelte";

    export async function getPositions(isPlain = false) {
        const apiResp = await fetchWithHeaders(querifiedURL(getBaseURL() + '/position-management/positions', { plain: isPlain ? 1 : 0}));
        return apiResp;
    }
    
    export async function createPosition(positionData) {
        return await fetchWithHeaders(getBaseURL() + '/position-management/positions', {
            method: 'POST',
            body: JSON.stringify(positionData)
        });
    }
    
    export async function getPositionByID(positionID) {
        const apiResp = await fetchWithHeaders(getBaseURL() + '/position-management/positions/' + positionID);
        return apiResp;
    }
    
    export async function deletePositionByID(positionID) {
        return await fetchWithHeaders(getBaseURL() + '/position-management/positions/' + positionID, {
            method: 'DELETE',
        });
    }
    
    export async function updatePositionByID(positionID, positionData) {
        return await fetchWithHeaders(getBaseURL() + '/position-management/positions/' + positionID, {
            method: 'PUT',
            body: JSON.stringify(positionData)
        });
    }
    
    export async function getPositionProperties(positionID, prop = null) {
        const apiResp = await fetchWithHeaders(getBaseURL() + '/position-management/positions/' + positionID + '/properties' + (prop ? `?prop=${prop}` : ''));
        return apiResp;
    }

    export async function updatePositionPriorities(newPositions) {
        const payload = {
            new_positions: newPositions
        };
        return await fetchWithHeaders(getBaseURL() + '/position-management/change-position-priorities', {
            method: 'PUT',
            body: JSON.stringify(payload)
        });
    }
    
</script>