<script>
    import { onMount } from "svelte";
    import { navigate } from "svelte-routing";
    import LoginTemplate from "../../../components/LoginTemplate/LoginTemplate.svelte";
    import TextInput from "../../../components/Form/TextInput.svelte";
    import { mobileStore } from "../../../store/mobile.store";
    import { get } from "svelte/store";
    import { phoneVerification } from "../../../helpers/apiHelper.svelte";
    import { notification } from "../../../notification-handlers/notification.svelte";
    let isLoading = false;

    onMount(() => {
      notification.modal({
        title: 'Bilgilendirme !',
        message: 'Lütfen cep telefonunuza gelen kodu giriniz.'
      })
    })

    const handleSubmit = async (e) => {
        const formData = new FormData(e.target);
        const verificationCode = formData.get('verificationCode');
        mobileStore.setVerificationCode(verificationCode);
        const { mobileNumber } = get(mobileStore);
        const response = await phoneVerification(mobileNumber, verificationCode);
        if (response.success) {
          navigate('/signup/set-first-password');
        } else {
          notification.modal({
            title: 'Hata !',
            message: response.message
          });
        }
    }
    
</script>
<LoginTemplate>
    <form id="verification-form" on:submit|preventDefault={handleSubmit}>
        <div class="mb-3 w-full flex justify-center items-center">
        </div>
        <div class="relative w-full mb-3">
          <TextInput
            fieldName="verificationCode"
            labelText="Doğrılama Kodu"
            required
            placeholder="*******"
          />
        </div>
  
        <div class="text-center mt-6">
          <button
            class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150 disabled:opacity-50"
            type="submit"
            disabled={isLoading}
            form="verification-form"
          >
            DOGRULA
          </button>
        </div>
      </form>
</LoginTemplate>